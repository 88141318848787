import molecule from '@dtinsight/molecule'
import { isNaN, parseInt, round } from 'lodash'
import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  Row,
  Stack,
} from 'react-bootstrap'
import Feedback from 'react-bootstrap/esm/Feedback'
import { rpcExecuteIsolatedCompiledLua } from 'src/api/deviceStateDetector'
import { FW_VERSION } from 'src/api/fwVersion'
import { getLatestAPIVersion } from 'src/api/luaVersions'
import {
  LOGGER_VERSION,
  ScanContext,
  ScanDeviceInfo,
  ScanReqType,
} from 'src/api/scanner'
import { DEVICE_STATE, SerialContext } from 'src/api/serialProvider'
import { LuaAction } from 'src/extensions/luaCompiler/base'
import { getCurrentTime } from 'src/utils/timeUtils'
import {
  MBusScannerResultMsg,
  MBusScannerResultMsgLvl,
  MBusScannerResults,
} from './MBusScannerResults'

/* TODO:
 * decode manID using https://www.m-bus.de/man.html [DONE]
 * hide primary address columnd during secondary scan [DONE]
 * logging [DONE]
 * primary shows different id from secondary (caused by BCD) [DONE]
 * allow showing complete frame [DONE]
 * allow showing parsed frame (if secondary scan -> needs to send another request to device) [PRIMARY DONE]
 * logging - export logs [DONE]
 * check shorted mbus ("...[STDOUT]: 0\t-1\t-1\t3172\t4411 ") [DONE]
 *    (state returns -1 UL -> cancel scan;
 *                    0 UL -> sus, but let scan run; )
 * better progress bar (show state) [DONE]
 * hide buttons should have different color [DONE]
 * collision during seondary scan [NEEDS TESTING]
 * hide scanner for non mbus conv. (show for *N_M* or *L_M* or *N_RM*)
 * check state before scanning
 * old model returns nil from mbusState -> modify regex
 * dispay warning when user changes setup paremeters: "using non standard parameters" [DONE]
 * primary from 0 to 252 (253 is something?, 254 is broadcast, 255 is 1way broadcast) [DONE]
 * change scan button to spinner when scanning [DONE]
 * secondary scan: if any scan finds no device, prompt user to try the other scan
 * Primary scan; Secondary scan; Primary Bcast; Primary Unicast; Secondary Unicast; [DONE]
 * secondary details [DONE]
 * export details as json
 * capitalize all words [DONE]
 * solve dialog timeout issue [DONE]
 * print scan params to logs [DONE]
 */

type MBusScannerModalProps = {
  show: boolean
  onClose: () => void
}

enum AddressingType {
  PRIMARY_SCAN = 'primary_scan',
  PRIMARY_BCAST = 'primary_bcast',
  PRIMARY_UNICAST = 'primary_unicast',
  SECONDARY_SCAN = 'secondary_scan',
  SECONDARY_UNICAST = 'secondary_unicast',
}

const MBusScannerModal: React.FC<MBusScannerModalProps> = ({
  onClose,
  show,
}) => {
  const serialCtx = useContext(SerialContext)

  // select options
  const parityOptions = [
    { value: '0', label: 'None' },
    { value: '1', label: 'Odd' },
    { value: '2', label: 'Even' }, // default
  ]
  const stopBitsOptions = [
    { value: '1', label: '1' }, // default
    { value: '2', label: '2' },
  ]
  const dataBitsOptions = [
    { value: '7', label: '7' },
    { value: '8', label: '8' }, // default
  ]
  const addressingOptions = [
    { value: AddressingType.PRIMARY_SCAN, label: 'Primary Scan' },
    { value: AddressingType.PRIMARY_UNICAST, label: 'Primary Unicast' },
    { value: AddressingType.PRIMARY_BCAST, label: 'Primary Broadcast' },
    { value: AddressingType.SECONDARY_SCAN, label: 'Secondary Scan' },
    { value: AddressingType.SECONDARY_UNICAST, label: 'Secondary Unicast' },
  ]

  // form state
  const [valid, setValid] = useState(false)
  const [baudRate, setBaudRate] = useState('2400')
  const [parity, setParity] = useState(parityOptions[2].value)
  const [stopBits, setStopBits] = useState(stopBitsOptions[0].value)
  const [dataBits, setDataBits] = useState(dataBitsOptions[1].value)
  const [addressing, setAddressing] = useState(addressingOptions[0].value)
  const [delay, setDelay] = useState('3500')
  const [secUniDelay, setSecUniDelay] = useState('150')

  // primary addressing parameters
  const [firstAddress, setFirstAddress] = useState('0')
  const [lastAddress, setLastAddress] = useState('252')
  const [currFirstAddress, setCurrFirstAddress] = useState(0)
  const [currLastAddress, setCurrLastAddress] = useState(252)

  // secondary addressing parameters
  const [secondaryAddress, setSecondaryAddress] = useState('00000000')

  // output
  const lastStateResp = useRef<number[]>([])
  const [scanResults, setScanResults] = useState<ScanDeviceInfo[][]>([]) // [0]=latest, [len-1]=oldest
  const [scanMsgs, setScanMsgs] = useState<MBusScannerResultMsg[][]>([]) // [0]=latest, [len-1]=oldest
  const [progress, setProgress] = useState(0)
  const [statusDisp, setStatusDisp] = useState('')
  const foundDevicesRef = useRef(0)
  const [foundDevices, setFoundDevices] = useState(0)

  // validate form before starting scan
  const handleSubmit = async (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValid(true)
  }

  // add new msh to latest scan
  const pushMsg = (msg: MBusScannerResultMsg) => {
    setScanMsgs((prevMsgs) => {
      return prevMsgs.map((msgArr, idx) => {
        let cloned = [...msgArr]
        if (idx == 0) {
          cloned.push(msg)
        }
        return cloned
      })
    })
  }

  // hande state info
  const onStateInfo = (response: number[]) => {
    lastStateResp.current = [...response]

    // is response from old HW?
    if (response.length == 0) {
      pushMsg({
        level: MBusScannerResultMsgLvl.INFO,
        msg: 'Scan started.',
      })
      return
    }

    // new HW
    const [status, unitLoads, current_uA, refVoltage_mV, mbusCompLevel] =
      response

    pushMsg({
      level: MBusScannerResultMsgLvl.INFO,
      msg: `M-Bus status = ${status}, unitLoads = ${unitLoads}, current = ${round(current_uA / 1000, 2)} mA, refVoltage = ${refVoltage_mV} mV, compLevel = ${mbusCompLevel}`,
    })

    // ul == -1 -> shorted, TODO: cancel scan
    if (unitLoads === -1) {
      pushMsg({
        level: MBusScannerResultMsgLvl.ERROR,
        msg: 'UnitLoads = -1, M-Bus is shorted.',
      })
    }

    // ul == 0 -> sus, but let scan run
    if (unitLoads === 0) {
      pushMsg({
        level: MBusScannerResultMsgLvl.WARNING,
        msg: 'UnitLoads = 0, check if meters are connected.',
      })
    }
  }

  // device info msg handler
  const onDeviceInfo = (addr, info) => {
    setProgress((prev) => prev + 1)

    if (info !== undefined) {
      setFoundDevices((prev) => prev + 1)
      foundDevicesRef.current += 1
      setScanResults((prevVal) => {
        return prevVal.map((devInfoArr, idx) => {
          let cloned = [...devInfoArr]
          if (idx == 0) {
            cloned.push(info)
          }
          return cloned
        })
      })
    }
  }

  // scan done msg handler
  const onScanDone = () => {
    // check found vs ULs
    if (
      foundDevicesRef.current === 0 &&
      lastStateResp.current.length != 0 &&
      lastStateResp.current[1] > 0
    ) {
      pushMsg({
        level: MBusScannerResultMsgLvl.WARNING,
        msg: 'Scan finished. Found 0 meters but UnitLoads > 0 indicates that some meters are connected.',
      })
    } else {
      pushMsg({
        level: MBusScannerResultMsgLvl.INFO,
        msg: `Scan finished. Found ${foundDevicesRef.current} meters.`,
      })
    }
    molecule.event.EventBus.emit(LuaAction.SCAN_MODE_STOP)
  }

  // error state entry handler
  const onFirstErrorState = () => {
    // report error
    pushMsg({
      level: MBusScannerResultMsgLvl.ERROR,
      msg: 'Entered error state. Trying to recover...',
    })
  }

  // error state recovery handler
  const onErrorStateRecovery = () => {
    pushMsg({
      level: MBusScannerResultMsgLvl.INFO,
      msg: 'Recovery successful.',
    })
    molecule.event.EventBus.emit(LuaAction.SCAN_MODE_STOP)
  }

  // logger
  const onLogging = (msg: string) => {
    //console.log(msg)

    let logs = localStorage.getItem('scannerLogs')
    if (logs === null) {
      localStorage.setItem('scannerLogs', '')
      logs = ''
    }

    localStorage.setItem('scannerLogs', logs + msg + '\n')
  }

  // abort handler
  const onAborted = (msg: string) => {
    pushMsg({
      level: MBusScannerResultMsgLvl.WARNING,
      msg: `Button pressed. Aborting scan. ${msg}`,
    })
  }

  // return true if paremeters are valid, false otherwise
  const validateParams = () => {
    let isValid = true

    const br = parseInt(baudRate)
    const de = parseInt(delay)
    const fa = parseInt(firstAddress)
    const la = parseInt(lastAddress)
    const sa = parseInt(secondaryAddress, 16)
    const sr = parseInt(secUniDelay)

    // baudRate
    if (isNaN(br)) {
      pushMsg({
        level: MBusScannerResultMsgLvl.ERROR,
        msg: 'Baudrate must be a number.',
      })
      isValid = false
    } else {
      if (!(0 <= br && br <= 921600)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Baudrate must be within 0 and 921600.',
        })
        isValid = false
      } else if (br != 2400) {
        pushMsg({
          level: MBusScannerResultMsgLvl.WARNING,
          msg: 'Using uncommon baudrate. Default is 2400.',
        })
      }
    }

    // parity
    if (parity != parityOptions[2].value) {
      pushMsg({
        level: MBusScannerResultMsgLvl.WARNING,
        msg: "Using uncommon parity. Default is 'Even'.",
      })
    }

    // stop bits
    if (stopBits != stopBitsOptions[0].value) {
      pushMsg({
        level: MBusScannerResultMsgLvl.WARNING,
        msg: 'Using uncommon stop bits. Default is 1.',
      })
    }

    // data bits
    if (dataBits != dataBitsOptions[1].value) {
      pushMsg({
        level: MBusScannerResultMsgLvl.WARNING,
        msg: 'Using uncommon data bits. Default is 8.',
      })
    }

    // preheat delay
    if (isNaN(de)) {
      pushMsg({
        level: MBusScannerResultMsgLvl.ERROR,
        msg: 'Delay must be a number.',
      })
      isValid = false
    } else {
      if (!(0 <= de && de <= 921600)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Delay must be within 0 and 2147483647.',
        })
        isValid = false
      } else if (de < 2000) {
        pushMsg({
          level: MBusScannerResultMsgLvl.WARNING,
          msg: 'The preheat delay value is lower that the recommended minimum (2000). Some meters might not be ready to answer.',
        })
      } else if (de > 60000) {
        pushMsg({
          level: MBusScannerResultMsgLvl.WARNING,
          msg: 'The preheat delay is unnecessarily long.',
        })
      }
    }

    // first and last address (PRIMARY_SCAN only)
    if (addressing == AddressingType.PRIMARY_SCAN) {
      if (isNaN(fa)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'First address must be a number.',
        })
        isValid = false
      }
      if (isNaN(la)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Last address must be a number.',
        })
        isValid = false
      }
      if (!isNaN(fa) && !isNaN(la)) {
        if (!(0 <= fa)) {
          pushMsg({
            level: MBusScannerResultMsgLvl.ERROR,
            msg: 'First address must not be be lesser than 0.',
          })
          isValid = false
        }
        if (!(la <= 252)) {
          pushMsg({
            level: MBusScannerResultMsgLvl.ERROR,
            msg: 'Last address must not be greater than 252.',
          })
          isValid = false
        }
        if (!(fa <= la)) {
          pushMsg({
            level: MBusScannerResultMsgLvl.ERROR,
            msg: 'First address must not be greater than last address.',
          })
          isValid = false
        }
      }
    }

    // target address (SECONDARY_UNICAST only)
    if (addressing == AddressingType.SECONDARY_UNICAST) {
      if (isNaN(sa)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Target address must be a hex. number.',
        })
        isValid = false
      } else if (secondaryAddress.trim().length > 8) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Target address must not contain more than 8 digits.',
        })
        isValid = false
      }
    }

    // target address (PRIMARY_UNICAST only)
    if (addressing == AddressingType.PRIMARY_UNICAST) {
      if (isNaN(fa)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Target address must be a number.',
        })
        isValid = false
      } else if (!(0 <= fa && fa <= 252)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Address must be within 0 and 252.',
        })
        isValid = false
      }
    }

    // secondary Select-Request delay
    if (
      addressing == AddressingType.SECONDARY_UNICAST ||
      addressing == AddressingType.SECONDARY_SCAN
    ) {
      if (isNaN(sr)) {
        pushMsg({
          level: MBusScannerResultMsgLvl.ERROR,
          msg: 'Select-Request delay must be a number.',
        })
        isValid = false
      } else {
        if (!(0 <= sr && sr <= 10000)) {
          pushMsg({
            level: MBusScannerResultMsgLvl.ERROR,
            msg: 'The select-request delay must be within 0 and 10000. Default is 150.',
          })
          isValid = false
        } else if (sr < 50) {
          pushMsg({
            level: MBusScannerResultMsgLvl.WARNING,
            msg: 'The select-request delay might be too short.',
          })
        } else if (5000 < sr) {
          pushMsg({
            level: MBusScannerResultMsgLvl.WARNING,
            msg: 'The select-request delay might be too long.',
          })
        }
      }
    }

    return isValid
  }

  // TODO: move script builders to scanner.ts
  const scriptSecAddrDetails = (secUniDelay) => {
    return `
function mbusSecondaryAddressing(meterID)
    meterID = string.format("%08X", meterID)
    local idByte1 = tonumber(string.sub(meterID, 1, 2), 16)
    local idByte2 = tonumber(string.sub(meterID, 3, 4), 16)
    local idByte3 = tonumber(string.sub(meterID, 5, 6), 16)
    local idByte4 = tonumber(string.sub(meterID, 7, 8), 16)
    -- api.mbusState(1)
    -- api.delayms(10000)
    local selectMessageNoChecksum = {0x68, 0x0B, 0x0B, 0x68, 0x53, 0xFD, 0x52, idByte4, idByte3, idByte2, idByte1, 0xFF, 0xFF, 0xFF, 0xFF}
    local checksum = 0
    for i = 5, #selectMessageNoChecksum do
        checksum = checksum + selectMessageNoChecksum[i]
    end
    checksum = bit.band(checksum, 0xFF)
    local selectMessage = pack.pack("b17", selectMessageNoChecksum[1], selectMessageNoChecksum[2], selectMessageNoChecksum[3], selectMessageNoChecksum[4],
                                    selectMessageNoChecksum[5], selectMessageNoChecksum[6], selectMessageNoChecksum[7], selectMessageNoChecksum[8],
                                    selectMessageNoChecksum[9], selectMessageNoChecksum[10], selectMessageNoChecksum[11], selectMessageNoChecksum[12],
                                    selectMessageNoChecksum[13], selectMessageNoChecksum[14], selectMessageNoChecksum[15], checksum, 0x16)
    -- print("SELECT: ") api.dumpArray(selectMessage, "raw")
    local res, _, _, _, ans = api.mbusTransaction(selectMessage, 1000, 1)
    local statusSelect = (ans and ans:byte(1) == 0xE5) and "Select successful" or "Select failed"
    api.delayms(${secUniDelay})
    local requestMessage = pack.pack("b5", 0x10, 0x7B, 0xFD, 0x78, 0x16)
    -- print("REQ_UD2: ") api.dumpArray(requestMessage, "raw")
    local res, c, a, ci, ans, raw = api.mbusTransaction(requestMessage, 2000, 3)
    local statusRequest = (res > 0) and "Request successful" or "Request failed"
    local userData = statusRequest and raw or ""
    -- api.mbusState(0)
    return statusSelect, statusRequest, userData
end`
  }

  // script builders
  const scriptPrimary = (
    baudRate,
    parity,
    stopBits,
    dataBits,
    delay,
    firstAddress,
    lastAddress
  ) => {
    return `
api.mbusSetup(${baudRate},${parity},${stopBits},${dataBits})
print(api.mbusState(1))
api.delayms(${delay})
for i = ${firstAddress},${lastAddress} do
  if api.stdin("readbutton") == 1 then print("ABORTED") break end
  cs = i + 0x5B
  cs = cs % 256
  b=pack.pack('<b5', 0x10,0x5B,i,cs,0x16)
  status,c,a,ci,ans,raw = api.mbusTransaction(b,500,1)
  print("Primary="..tostring(i))
  api.dumpArray(raw,"raw")
end
api.mbusState(0)
print("DONE SCANNING")`
  }

  const scriptSecondary = (baudRate, parity, stopBits, dataBits, delay) => {
    return (
      scriptSecAddrDetails(secUniDelay) +
      `
api.mbusSetup(${baudRate},${parity},${stopBits},${dataBits}) 
print(api.mbusState(1))
api.delayms(${delay})
function hex(n)
  return string.format("%08X",n)
end
e,cnt = api.mbusScan("")
print("Found "..tostring(cnt).." devices")
for j = 1,cnt do
  print("found id = "..e[j].identification..", manid = "..e[j].manufacturer..", ver = "..e[j].version..", medium = "..e[j].medium)
  statusSelect, statusRequest, userData = mbusSecondaryAddressing(e[j].identification)
  api.dumpArray(userData,"raw")
end 
api.mbusState(0)
print("DONE SCANNING")`
    )
  }

  const scriptSecondaryUnicast = (
    baudRate,
    parity,
    stopBits,
    dataBits,
    delay,
    secondaryAddress
  ) => {
    return (
      scriptSecAddrDetails(secUniDelay) +
      `
api.mbusSetup(${baudRate},${parity},${stopBits},${dataBits}) 
print(api.mbusState(1))
api.delayms(${delay})
function hex(n)
  return string.format("%08X",n)
end
statusSelect, statusRequest, userData = mbusSecondaryAddressing(tonumber('${parseInt(secondaryAddress, 16)}'))
api.dumpArray(userData,"raw")
api.mbusState(0)
print("DONE SCANNING")`
    )
  }

  // send scan handler
  const sendScan = async () => {
    setProgress(0)
    setFoundDevices(0)
    foundDevicesRef.current = 0
    setScanResults((prevVal) => {
      return [[], ...prevVal.map((devInfoArr) => [...devInfoArr])]
    })
    setScanMsgs((prevVal) => {
      return [[], ...prevVal.map((msgArr) => [...msgArr])]
    })

    if (!validateParams()) {
      return
    }

    setCurrFirstAddress(parseInt(firstAddress))
    setCurrLastAddress(parseInt(lastAddress))

    console.log('Starting sending M-Bus scan request')

    let scanScript: string
    let scanCtx: ScanContext

    switch (addressing) {
      case AddressingType.PRIMARY_SCAN:
        scanCtx = {
          reqType: ScanReqType.PRIMARY,
          lastAddr: parseInt(lastAddress),
          onStateInfo,
          onDeviceInfo,
          onScanDone,
          onLogging,
          onFirstErrorState,
          onErrorStateRecovery,
          onAborted,
        }
        scanScript = scriptPrimary(
          baudRate,
          parity,
          stopBits,
          dataBits,
          delay,
          firstAddress,
          lastAddress
        )
        break

      case AddressingType.PRIMARY_UNICAST:
        scanCtx = {
          reqType: ScanReqType.PRIMARY,
          lastAddr: parseInt(firstAddress),
          onStateInfo,
          onDeviceInfo,
          onScanDone,
          onLogging,
          onFirstErrorState,
          onErrorStateRecovery,
          onAborted,
        }
        scanScript = scriptPrimary(
          baudRate,
          parity,
          stopBits,
          dataBits,
          delay,
          firstAddress,
          firstAddress
        )
        break

      case AddressingType.PRIMARY_BCAST:
        scanCtx = {
          reqType: ScanReqType.PRIMARY,
          lastAddr: parseInt(lastAddress),
          onStateInfo,
          onDeviceInfo,
          onScanDone,
          onLogging,
          onFirstErrorState,
          onErrorStateRecovery,
          onAborted,
        }
        scanScript = scriptPrimary(
          baudRate,
          parity,
          stopBits,
          dataBits,
          delay,
          '254',
          '254'
        )
        pushMsg({
          level: MBusScannerResultMsgLvl.WARNING,
          msg: 'Broadcast will work correctly only if at most 1 meter is connected.',
        })
        break

      case AddressingType.SECONDARY_SCAN:
        scanCtx = {
          reqType: ScanReqType.SECONDARY,
          onStateInfo,
          onDeviceInfo,
          onScanDone,
          onLogging,
          onFirstErrorState,
          onErrorStateRecovery,
          onAborted,
        }
        scanScript = scriptSecondary(
          baudRate,
          parity,
          stopBits,
          dataBits,
          delay
        )
        break

      case AddressingType.SECONDARY_UNICAST:
        scanCtx = {
          reqType: ScanReqType.SECONDARY_UNICAST,
          onStateInfo,
          onDeviceInfo,
          onScanDone,
          onLogging,
          onFirstErrorState,
          onErrorStateRecovery,
          onAborted,
        }
        scanScript = scriptSecondaryUnicast(
          baudRate,
          parity,
          stopBits,
          dataBits,
          delay,
          secondaryAddress
        )
        break
    }

    const { major, minor, patch } = serialCtx.deviceInfo.current.fwVersion
    onLogging(
      `\n[SCANNER][${getCurrentTime()}]: LOGGER_VERSION=${LOGGER_VERSION} DEVICE=${serialCtx.deviceInfo.current.type} LEGACY=${serialCtx.deviceInfo.current.legacy} FW=${major}.${minor}.${patch} SCAN_SCRIPT=${scanScript}`
    )
    molecule.event.EventBus.emit(LuaAction.SCAN_MODE_START, scanCtx)

    await rpcExecuteIsolatedCompiledLua(serialCtx.port, scanScript)

    console.log('Finished sending M-Bus scan request')
  }

  const exportLogs = () => {
    const d = new Date()
    const fileName = `ACRIOS_SCANNER_${d.toISOString().replaceAll(':', '_')}.txt`

    let logs = localStorage.getItem('scannerLogs')
    if (logs === null) {
      localStorage.setItem('scannerLogs', '')
      logs = ''
    }

    // download currently open file using a virtual element
    let element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(logs)
    )
    element.setAttribute('download', fileName)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  // checks if the connected device is using FW >= 2.14.2
  const hasRequiredFW = () => {
    const { major, minor, patch } = serialCtx.deviceInfo.current.fwVersion

    if (major > 2) return true
    if (major == 2) {
      if (minor > 14) return true
      if (minor == 14) {
        return patch >= 2
      }
    }

    return false
  }

  // print FW version
  const printFW = () => {
    const { major, minor, patch } = serialCtx.deviceInfo.current.fwVersion
    return `${major}.${minor}.${patch}`
  }

  if (!hasRequiredFW()) {
    return (
      <Modal
        show={show}
        size="lg"
        data-bs-theme="dark"
        className="acrios-modal"
        onHide={onClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>M-Bus Scanner</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-warning mb-3 fw-bolder">
            Using firmware version {printFW()}. M-Bus Scanner requires firmware
            version 2.14.2 or newer.
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal
      show={show}
      size="lg"
      data-bs-theme="dark"
      className="acrios-modal"
      onHide={onClose}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>M-Bus Scanner</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!hasRequiredFW() && (
          <div className="text-warning mb-3 fw-bolder">
            Using firmware version {printFW()}. M-Bus Scanner requires firmware
            version 2.14.2 or newer.
          </div>
        )}

        <Form noValidate validated={valid} onSubmit={handleSubmit}>
          <Row className="g-3 mb-3">
            <FormGroup as={Col} className="col-md-4" controlId="baudRateFG">
              <FormLabel>Baudrate</FormLabel>
              <FormControl
                required
                type="text"
                list="baudRatePresets"
                value={baudRate}
                onChange={(e) => {
                  setBaudRate(e.target.value)
                }}
                disabled={
                  !hasRequiredFW() ||
                  serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                }
              />
              <datalist id="baudRatePresets">
                {['2400', '4800', '9600'].map((br, i) => (
                  <option value={br} key={i} />
                ))}
              </datalist>
              <Feedback>TODO</Feedback>
            </FormGroup>

            <FormGroup as={Col} className="col-md-4" controlId="parityFG">
              <FormLabel>Parity</FormLabel>
              <FormSelect
                value={parity}
                onChange={(e) => {
                  setParity(e.target.value)
                }}
                disabled={
                  !hasRequiredFW() ||
                  serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                }
              >
                {parityOptions.map((opt, i) => (
                  <option value={opt.value} key={i}>
                    {opt.label}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>

            <FormGroup as={Col} className="col-md-2" controlId="stopBitsFG">
              <FormLabel>Stop Bits</FormLabel>
              <FormSelect
                value={stopBits}
                onChange={(e) => {
                  setStopBits(e.target.value)
                }}
                disabled={
                  !hasRequiredFW() ||
                  serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                }
              >
                {stopBitsOptions.map((opt, i) => (
                  <option value={opt.value} key={i}>
                    {opt.label}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>

            <FormGroup as={Col} className="col-md-2" controlId="dataBitsFG">
              <FormLabel>Data Bits</FormLabel>
              <FormSelect
                value={dataBits}
                onChange={(e) => {
                  setDataBits(e.target.value)
                }}
                disabled={
                  !hasRequiredFW() ||
                  serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                }
              >
                {dataBitsOptions.map((opt, i) => (
                  <option value={opt.value} key={i}>
                    {opt.label}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          </Row>

          <Row className="g-3 mb-3">
            <FormGroup as={Col} className="col-md-4" controlId="delayFG">
              <FormLabel>Bus Preheat Delay [ms]</FormLabel>
              <FormControl
                required
                type="text"
                value={delay}
                onChange={(e) => {
                  setDelay(e.target.value)
                }}
                disabled={
                  !hasRequiredFW() ||
                  serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                }
              />
              <Feedback>TODO</Feedback>
            </FormGroup>
          </Row>

          <Row className="g-3 mb-3">
            <FormGroup as={Col} className="col-md-4" controlId="addressingFG">
              <FormLabel>Addressing</FormLabel>
              <FormSelect
                value={addressing}
                onChange={(e) => {
                  setAddressing(e.target.value as AddressingType)
                }}
                disabled={
                  !hasRequiredFW() ||
                  serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                }
              >
                {addressingOptions.map((opt, i) => (
                  <option value={opt.value} key={i}>
                    {opt.label}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>

            {addressing == AddressingType.PRIMARY_SCAN && (
              <>
                <FormGroup
                  as={Col}
                  className="col-md-4"
                  controlId="firstAddressFG"
                >
                  <FormLabel>First&nbsp;Address</FormLabel>
                  <FormControl
                    required
                    type="text"
                    value={firstAddress}
                    onChange={(e) => {
                      setFirstAddress(e.target.value)
                    }}
                    disabled={
                      !hasRequiredFW() ||
                      serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                    }
                  />
                  <Feedback>TODO</Feedback>
                </FormGroup>

                <FormGroup
                  as={Col}
                  className="col-md-4"
                  controlId="lastAddressFG"
                >
                  <FormLabel>Last&nbsp;Address</FormLabel>
                  <FormControl
                    required
                    type="text"
                    value={lastAddress}
                    onChange={(e) => {
                      setLastAddress(e.target.value)
                    }}
                    disabled={
                      !hasRequiredFW() ||
                      serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                    }
                  />
                  <Feedback>TODO</Feedback>
                </FormGroup>
              </>
            )}

            {addressing == AddressingType.PRIMARY_UNICAST && (
              <>
                <FormGroup
                  as={Col}
                  className="col-md-4"
                  controlId="firstAddressFG"
                >
                  <FormLabel>Target&nbsp;Address</FormLabel>
                  <FormControl
                    required
                    type="text"
                    value={firstAddress}
                    onChange={(e) => {
                      setFirstAddress(e.target.value)
                    }}
                    disabled={
                      !hasRequiredFW() ||
                      serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                    }
                  />
                  <Feedback>TODO</Feedback>
                </FormGroup>
              </>
            )}

            {addressing == AddressingType.SECONDARY_SCAN && (
              <FormGroup as={Col} className="col-md-4" controlId="secUniDelay">
                <FormLabel>Select-Request&nbsp;Delay [ms]</FormLabel>
                <FormControl
                  required
                  type="text"
                  value={secUniDelay}
                  onChange={(e) => {
                    setSecUniDelay(e.target.value)
                  }}
                  disabled={
                    !hasRequiredFW() ||
                    serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                  }
                />
                <Feedback>TODO</Feedback>
              </FormGroup>
            )}

            {addressing == AddressingType.SECONDARY_UNICAST && (
              <>
                <FormGroup
                  as={Col}
                  className="col-md-4"
                  controlId="secondaryAddress"
                >
                  <FormLabel>Target&nbsp;Address</FormLabel>
                  <FormControl
                    required
                    type="text"
                    value={secondaryAddress}
                    onChange={(e) => {
                      setSecondaryAddress(e.target.value)
                    }}
                    disabled={
                      !hasRequiredFW() ||
                      serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                    }
                  />
                  <Feedback>TODO</Feedback>
                </FormGroup>
                <FormGroup
                  as={Col}
                  className="col-md-4"
                  controlId="secUniDelay"
                >
                  <FormLabel>Select-Request&nbsp;Delay [ms]</FormLabel>
                  <FormControl
                    required
                    type="text"
                    value={secUniDelay}
                    onChange={(e) => {
                      setSecUniDelay(e.target.value)
                    }}
                    disabled={
                      !hasRequiredFW() ||
                      serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
                    }
                  />
                  <Feedback>TODO</Feedback>
                </FormGroup>
              </>
            )}
          </Row>

          <Stack direction="horizontal" gap={3} className="mb-3">
            <Button
              variant="primary"
              onClick={sendScan}
              disabled={
                !hasRequiredFW() ||
                serialCtx.deviceState === DEVICE_STATE.SCAN_MODE
              }
            >
              {serialCtx.deviceState !== DEVICE_STATE.SCAN_MODE && (
                <>
                  {(addressing === AddressingType.PRIMARY_SCAN ||
                    addressing === AddressingType.SECONDARY_SCAN) &&
                    'Start Scan'}
                  {(addressing === AddressingType.PRIMARY_BCAST ||
                    addressing === AddressingType.PRIMARY_UNICAST ||
                    addressing === AddressingType.SECONDARY_UNICAST) &&
                    'Send Request'}
                </>
              )}
              {serialCtx.deviceState === DEVICE_STATE.SCAN_MODE && (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Button>
            <Button variant="secondary" onClick={exportLogs}>
              Export Logs
            </Button>

            <div className="ms-auto">
              {addressing === AddressingType.PRIMARY_SCAN &&
                (progress != 0 ||
                  serialCtx.deviceState === DEVICE_STATE.SCAN_MODE) &&
                `Progress: ${progress} / ${1 + currLastAddress - currFirstAddress}, Found: ${foundDevices}`}
              {addressing === AddressingType.SECONDARY_SCAN &&
                serialCtx.deviceState === DEVICE_STATE.SCAN_MODE &&
                `Scan in progress..., Found: ${foundDevices}`}
              {addressing === AddressingType.SECONDARY_SCAN &&
                serialCtx.deviceState !== DEVICE_STATE.SCAN_MODE &&
                foundDevices != 0 &&
                `Found: ${foundDevices}`}
            </div>
          </Stack>
        </Form>

        <div>
          {scanResults.map((result, idx) => (
            <MBusScannerResults
              msgs={scanMsgs[idx]}
              devices={result}
              key={idx}
            />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MBusScannerModal
